<template>
	<div class="d-flex flex-column">
		
		<div class="d-flex flex-row border-bottom align-items-center py-2 px-4">
			<h4 class="mb-0 pb-0">Details</h4>

			<div class="d-inline-block flex-fill text-end">
				<button type="submit" class="btn btn-sm py-0 btn-outline-dark text-uppercase me-3" @click="restore">Restore</button>
				<button type="submit" class="btn btn-sm py-0 btn-danger text-uppercase" @click="save">Update Details</button>
			</div>
		</div>


		<form v-if="current.data?.title" class="form-details py-4 flex-fill overflow-scroll" @submit.prevent=""
			:style="{
				'padding-left': '36px!important',
				'padding-right': '36px!important',
				height: '100px'
			}"		
			>
			<div class="mb-3">
				<label for="field-title" class="form-label">Label</label>
				<input type="text" class="form-control text-input fs-5" id="field-title" v-model="current.data.title">
				<div class="form-text">The title for this field <i>( name: {{current.data?.name}} )</i>.</div>
			</div>


			<div class="mb-3">
				<label for="field-key" class="form-label">Key</label>
				<input type="text" class="form-control text-input fs-5" id="field-key" v-model="current.data.key">
				<div class="form-text">What field name would you like to use? —e.g. "name", "age", etc.</div>
			</div>

			<div class="mb-3">
				<label for="field-hint" class="form-label">Hint</label>
				<input type="text" class="form-control text-input fs-5" id="field-hint" v-model="current.data.hint">
				<div class="form-text">Provide a hint for how to fill out this field.</div>
			</div>

			<div class="mb-3">
				<label for="field-type" class="form-label">Type</label>
				<select class="form-select text-input fs-5" v-model="current.data.type" id="filed-type">
					<option v-for="(type, index) in types" :key="index" :value="type.type">{{ type.name }}</option>
				</select>
				<div class="form-text">Choose the type of data.</div>
			</div>

			<div class="d-block mb-3 pt-3">
				<a href="#" class="btn btn-sm btn-outline-primary py-0"
					@click.prevent="showAdvancedOptions = !showAdvancedOptions">
					{{ showAdvancedOptions ? 'HIDE ADVANCED' : 'SHOW ADVANCED'}}
				</a>
			</div>


			<span v-show="showAdvancedOptions">

				<div class="mb-3">
					<label for="field-description" class="form-label">Description</label>
					<input type="text" class="form-control text-input fs-5" id="field-description" v-model="current.data.description">
					<div class="form-text">Describe this filed.</div>
				</div>

				<div class="mb-3">
					<label for="field-default" class="form-label">Default</label>
					<input type="text" class="form-control text-input fs-5" id="field-default" v-model="current.data.default">
					<div class="form-text">Enter the default value.</div>
				</div>

				<div class="mb-3">
					<label for="field-options" class="form-label">Options</label>
					<input type="text" class="form-control text-input fs-5" id="field-options" v-model="current.data.options">
					<div class="form-text">Configuration options.</div>
				</div>

				<!-- MOVE TO FORM -->
				<!--
				<div class="mb-3">
					<a class="float-end">GENERATE</a>
					<label for="field-api" class="form-label">API Private Key</label>
					<input type="text" class="form-control text-input fs-5" id="field-api" v-model="current.data.apiKey">
					<div class="form-text">Enter the default value.</div>
				</div>


				<div class="mb-3">
					<label for="field-options" class="form-label">Allow Domains</label>
					<input type="text" class="form-control text-input fs-5" id="field-options" v-model="current.data.options">
					<div class="form-text">Domains that can use APIs (e.g. "domain1.com, *.domain2.com").</div>
				</div>
				-->

			</span>


		</form>

		<div v-else  class="px-4 py-3 flex-fill">
			<p>Select the field you wish to edit</p>
		</div>

		<!-- <div class="text-end px-4 py-2 border-top">
			<button type="submit" class="btn btn-outline-dark text-uppercase me-3" @click="restore">Restore</button>
			<button type="submit" class="btn btn-primary text-uppercase" @click="save">Update Details</button>
		</div> -->

		<!-- <div class="px-4">
			<pre>{{ current.data }}</pre>
		</div> -->
	</div>
</template>

<script>

import FiledTypes from "./FieldTypes";

export default {
	props: {
		update: {
			tpe: Function
		}
	}, 

	data() {
		return {
			hasChanged: false,
			wasSaved: true,
			showAdvancedOptions: false,
			source: null,
			current: {
				data: {}
			}
		}
	}, 

	computed: {
		types() {
			return FiledTypes;
		}
	},


	methods: {
		attachSource(source) {
			this.source = source;
			let data = JSON.parse( JSON.stringify(source) );
			this.current.data = data
		}, 

		async save() {
			typeof this.update == 'function' && await this.update(this.current.data);
		}, 

		restore() {
			this.attachSource(this.source);
		}
	}


};
</script>

<style>
</style>